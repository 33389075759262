import styled from "styled-components"

import { TextField } from "@material-ui/core"

import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"

export function DurationInput({
  value,
  onChange,
}: {
  value: number | null
  onChange: (newVal: number) => void
}) {
  const { t, langKeys } = useTranslate()
  const label = t(langKeys.duration)
  const hourAdornmentText = (
    value === 1 ? t(langKeys.hour) : t(langKeys.hours)
  ).toLocaleLowerCase()

  return (
    <TextField
      label={label}
      type="number"
      InputProps={{
        endAdornment: <HourAdornment>{hourAdornmentText}</HourAdornment>,
        inputProps: { min: 1, max: 24 },
      }}
      required
      value={value ?? ""}
      onChange={(e) => onChange(Number(e.target.value))}
    ></TextField>
  )
}
const HourAdornment = styled.span`
  margin-left: ${spacing.M};
`
